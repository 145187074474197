import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import TratamientosImg from '../../media/imagenes/tratamiento.jpg'

function CardsTratamientos() {
  return (
    <div className='cards'>
      <h1>Tratamientos.</h1>
      <div className="tratamientos-section">
        <div className="tratamientos-section-info">
          <small>Con el apoyo de técnicas de imagen y procedimientos de mínima invasión, se realizan procedimientos para el tratamiento parcial o definitivo de múltiples patologías.</small><br /><br />
          <strong>Quimioembolización arterial selectiva.</strong><br />
          <small>Es un procedimiento terapéutico mínimamente invasivo que se emplea en radiología intervencionista para restringir el suministro de sangre a un tumor.</small><br /><br />
          <strong>Angioplastias.</strong><br />
          <small>Es una cirugía para restaurar el flujo de sangre en arterias en algún lugar específico del cuerpo.</small><br /><br />
          <strong>Ablación de tumores.</strong><br />
          <small>Es un procedimiento mínimamente invasivo que utiliza energía eléctrica y calor para destruir células cancerosas.</small><br /><br />
          <strong>Angiografías.</strong><br />
          <small>Para estudiar el interior de los vasos sanguíneos y tratar problemas como estrechamientos o aneurismas.</small><br /><br />
          <strong>Tratamientos óseos o tumorales.</strong><br />
          <small>Para colocar pequeñas agujas dentro del tumor y destruirlo mientras se protege el tejido circundante.</small><br /><br />
          <strong>Colocación de drenajes.</strong><br />
          <small>Para eliminar la acumulación de líquido en los pulmones o el abdomen.</small><br /><br />
          <strong>Insertar vías centrales (catéteres centrales de inserción periférica, dispositivos implantables para el acceso venoso, catéteres de diálisis, etc.)</strong><br /><br />
          <strong>Drenar un absceso.</strong><br />
          <small>Para drenar la acumulación de líquido purulento en algún sitio especifico.</small><br /><br />
          <strong>Colocar una sonda pleural para drenar líquido o pus de los pulmones.</strong><br /><br />
          <strong> Una biopsia.</strong><br />
          <small>Para la obtención de una muestra de tejido tumoral o de un órgano.</small><br /><br />
          <strong>Tratar malformaciones vasculares, entre las que se incluyen:</strong><br /><br />
          <ul className='info-list'>
            <li style={{marginLeft: "35px"}}>Malformaciones venosas.</li><br />
            <li style={{marginLeft: "35px"}}>Malformaciones linfáticas.</li><br />
            <li style={{marginLeft: "35px"}}>Malformaciones arteriovenosas.</li><br />
            <li style={{marginLeft: "35px"}}>Algunos tipos de hemangiomas (tumores formados por el crecimiento anormal de vasos sanguíneos en o debajo de la piel o dentro de los órganos internos).</li><br /><br />
          </ul>
          <strong> Destrucción de tumores óseos o de tejidos blandos usando tecnologías de ablación, entre las que se incluyen:</strong><br /><br />
          <ul className='info-list'> 
            <li style={{marginLeft: "35px"}}>Ablación por radiofrecuencia (ondas de radio de alta energía que calientan y destruyen células cancerosas).</li><br />
            <li style={{marginLeft: "35px"}}>Ablación por microondas.</li><br />
            <li style={{marginLeft: "35px"}}>Crioablación (congelación de tumores para destruirlos).</li><br /><br />
          </ul>
          <strong>Tratar con láser hemorragias y lesiones dolorosas en la piel, que ocurren en las malformaciones vasculares, para destruir vesículas (lo que se llama fotovaporización).</strong>


        </div>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={TratamientosImg}
              text='Los miomas uterinos son tumores benignos (no cancerígenos) que crecen en la pared del útero. Son los tumores más frecuentes del aparato genital femenino, entre el 40 y 50 % de mujeres a partir de los 40 años los padecen...'
              label='Embolizaciones de arterias uterinas'
              path="/tratamientos/miomas"
            />
            <CardItem
              src={TratamientosImg}
              text='La hiperplasia benigna de próstata (HBP) es un agrandamiento no canceroso de la glándula prostática cuya prevalencia aumenta progresivamente con la edad, llegando del 50% de los varones de 60 años al 90% de los mayores de 90 años....'
              label='Arterias prostáticas'
              path="/tratamientos/arterias-prostaticas"
            />
            <CardItem
              src={TratamientosImg}
              text='La insuficiencia venosa en forma de varices es una enfermedad frecuente en nuestro entorno, y que afecta casi a la mitad de la población....'
              label='Tratamiento de varices sin cirugía'
              path="/tratamientos/tratamiento-varices-sin-cirugia"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsTratamientos;