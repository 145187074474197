import React from 'react'
import CardsTratamientos from '../Cards/CardsTratamientos'

const Tratamiento = () => {
    return (
        <div className='tratamiento'>
            <CardsTratamientos/>
        </div>
    )
}

export default Tratamiento
