import React from 'react'
import './Diagnostico.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Diagnostico10 = () => {

    return (
        <div className='diagnostico'>
            <div className='diagnostico-container'>
                <div className='diagnostico-title'>
                    <h2>Punción de víscera profunda.</h2>
                </div>
                <div className='diagnostico-section'>
                    <div className='diagnostico-section-info'>
                        <strong>Punción aspiración aguja fina guiada por ecografía.</strong><br/>
                        <small className="small">Procedimiento mediante el que se toma una muestra de tejido para examinarla bajo un microscopio. Se inserta por la boca hasta el esófago un endoscopio con una sonda de ecografía y una aguja de biopsia.</small><br/><br />
                        <strong>Punción aspiración aguja fina guiada con TC.</strong><br/>
                        <small className="small">Es una prueba diagnóstica que consiste en la extracción de una muestra total o parcial de tejido para ser examinada al microscopio por un patólogo.</small><br/><br />
                        <small>Todos nuestros estudios y procedimientos se llevan a cabo en un área especializada de una clínica u hospital certificado (dependiendo de la condición o tratamiento).</small><br /><br />
                        <div className="diagnostico-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diagnostico10