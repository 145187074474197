import React from 'react'
import './Tratamiento.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Tratamiento2 = () => {

    return (
        <div className='tratamiento'>
            <div className='tratamiento-container'>
                <div className='tratamiento-title'>
                    <h2>¿Qué es la Hiperplasia prostática benigna?</h2>
                </div>
                <div className='tratamiento-section'>
                    <div className='tratamiento-section-info'>
                        <small>La hiperplasia benigna de próstata (HBP) es un agrandamiento no canceroso de la glándula prostática cuya prevalencia aumenta progresivamente con la edad, llegando del 50% de los varones de 60 años al 90% de los mayores de 90 años.</small><br /><br />
                        <small>La hiperplasia prostática benigna  (HPB) o adenoma de próstata es una de las enfermedades benignas mas frecuentes en el hombre y puede derivar en una hipertrofia benigna de próstata, en una obstrucción benigna de la próstata y en síntomas del aparato urinario inferior (STUI).</small><br /><br />
                        <small>Menos del 50% de los hombres con problemas de próstata consultan al médico. Los principales motivos por los que no se consulta suelen ser por considerarse los síntomas normales para la edad (70%), escepticismo sobre la eficacia del tratamiento (50%) y por miedo a la cirugía (25%) (Spatafora S, 2007).</small><br /><br />
                        <small>Cuando el tamaño de la próstata aumenta, la capa de tejido que la rodea impide que se dilate, haciendo que la glándula comprima la uretra.</small><br /><br />
                        <small>El crecimiento de la próstata dificulta la salida de orina y suele venir acompañado de síntomas urinarios  obstructivos (STUI) como:</small><br /><br />
                        <ul className='list-info'>
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Aumento de número de micciones tanto por el día como por la noche (Nicturia).</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Disminución de la fuerza habitual del chorro.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Goteo al terminar.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}> Necesidad de orinar con apremio o urgencia.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Se hace necesaria emplear más fuerza y/o tiempo para iniciar y mantener la micción.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Chorro de orina entrecortado</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Sensación de vaciado incompleto de la vejiga. Infecciones de orina.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>En algunos pacientes, estos síntomas interfieren con los patrones normales de sueño, reduciendo aún más su calidad de vida.</li><br /><br />
                        </ul>
                        <small>El tamaño de la próstata no siempre determina la gravedad de la obstrucción o los síntomas. Algunos hombres con glándulas muy aumentadas tienen poca obstrucción y pocos síntomas, mientras que otros, cuyas glándulas son menos grandes, tienen más bloqueo y mayores problemas.</small><br /><br /><br />
                        <strong>Embolización Prostática.</strong><br /><br />
                        <small>La embolización prostática es una técnica no quirúrgica, mínimamente invasiva, que mejora los síntomas de la hiperplasia benigna de próstata sin producir alteraciones de la función sexual.</small><br /><br />
                        <small>La embolización de la arteria prostática (EAP) la realizan radiólogos intervencionistas que colaboran con los urólogos para evaluar a los candidatos para este procedimiento.</small><br /><br />
                        <small>La Embolización de Próstata, es una técnica no quirúrgica, mínimamente invasiva realizada en la sala de Radiología Intervencionista del Hospital de forma ambulatoria, sin ingreso o con ingreso de un día.</small><br /><br />
                        <small>En este procedimiento el Médico Especialista, empleando anestesia local, introduce un catéter muy fino (como un tubito del tamaño de un spaghetti), a través de la arteria de la ingle hasta las arterias que llevan la sangre a la próstata. Luego se dirigen a través del catéter unas pequeñas partículas que embolizan las ramas arteriales a la  próstata, reduciendo la llegada de sangre, lo que provoca que la próstata disminuya de tamaño, reduciendo su compresión sobre la uretra y mejorando así los síntomas de obstrucción.</small><br /><br />
                        <small>La embolización de arterias prostáticas es una técnica segura que se ha empleado durante mucho tiempo con éxito para el control de los sangrados producidos accidentalmente por  biopsias o  por la cirugía prostática.</small>
                        <small>Las pequeñas partículas de embolización cierran la sangre que nutre a la próstata, reduciéndo ésta de tamaño y mejorando la salida de orina.La técnica requiere la valoración clínica previa del paciente por el servicio de urología (examen de próstata rectal, ecografía transrectal, análítica con PSA, uroflujometría y valoración uro dinámica, escala IPSS, Calidad de vida QoL, y escala internacional de función eréctil -IIEF) así como la realización de un Angio TAC vascular previo a la embolización prostática.</small><br /><br />
                        <small>Después de la embolización el paciente tomará un tratamiento antiinflamatorio unos días para evitar molestias y continúa el seguimiento de su evolución por el servicio de Urología de forma ambulatoria hasta que es dado definitivamente de alta.</small><br /><br />
                        <small>En ningún paciente sometido a embolización de próstata se han producido  alteraciones de la función sexual.</small><br /><br />
                        <small>La embolización de arterias prostáticas es una nueva técnica  con excelentes resultados clínicos, que no produce alteraciones en la función sexual por lo que está indicada como una alternativa a la cirugía de la próstata en pacientes con Hiperplasia Benigna de Próstata (HBP) que no mejoran con tratamiento médico y quieran conservar su fertilidad.</small><br /><br /><br />
                        <strong>Ventajas.</strong><br /><br />
                        <small>La embolización de la próstata mejora los síntomas de la hiperplasia de próstata sin producir alteraciones de la función sexual. Pero esta es solo una de sus múltiples ventajas.</small><br /><br />
                        <ul className="list-info">
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Puede realizarse en próstatas aumentadas de cualquier tamaño.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>No tiene efectos adversos/secundarios.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Toda la intervención se realiza por una pequeña incisión en la ingle de 2 mm.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>La mayoría de pacientes presentan unas molestias mínimas o nulas, y el alta hospitalaria es 24 Horas tras la intervención.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Una cuarta parte de pacientes sintieron mejoría en su función sexual tras el procedimiento.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Se realiza con anestesia local y ligera sedación.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>No hay pérdida de sangre ni necesidad de transfusiones.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>No deja cicatrices y la recuperación es rápida.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Mejora los síntomas en más del 95% de los pacientes.</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>Permite la retirada de las sondas urinarias .</li><br />
                        <li style={{marginLeft: "35px", fontSize: "medium"}}>No se producen disfunciones sexuales tras la embolización.</li><br /> <br /><br />
                        </ul>
                        <div className="tratamiento-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tratamiento2