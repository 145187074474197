import React from 'react';
import Diagnostico4 from '../Diagnostic/Diagnostico4';

const FlebografiaDiagnosticaSelectiva = () => {
  return <div>
      <Diagnostico4/>
  </div>;
};

export default FlebografiaDiagnosticaSelectiva;
