import React from 'react';
import Tratamiento2 from '../Tratamientos/Tratamientos2';

const HBP = () => {
  return <div>
      <Tratamiento2/>
  </div>;
};

export default HBP;
