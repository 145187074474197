import React from 'react'
import './Diagnostico.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Diagnostico4 = () => {

    return (
        <div className='diagnostico'>
            <div className='diagnostico-container'>
                <div className='diagnostico-title'>
                    <h2>Flebografía diagnóstica selectiva.</h2>
                </div>
                <div className='diagnostico-section'>
                    <div className='diagnostico-section-info'>
                        <strong>Flebografía de venas hepáticas.</strong><br/>
                        <small className="small">La flebografía consiste en introducir contraste intravenoso para ver el calibre y morfología de las venas suprahepáticas.</small><br/><br />
                        <strong> Flebografía renal.</strong><br/>
                        <small className="small">Es un examen para observar las venas del riñón. Se emplean rayos X y un colorante especial (llamado material de contraste).</small><br/><br />
                        <strong> Flebografía espermática u ovárica bilateral.</strong><br/>
                        <small className="small">Es una prueba diagnóstica para estudiar el flujo de los vasos espermáticos u ováricos.</small><br/><br /><br />
                        <small>Todos nuestros estudios y procedimientos se llevan a cabo en un área especializada de una clínica u hospital certificado (dependiendo de la condición o tratamiento).</small><br /><br />
                        <div className="diagnostico-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diagnostico4