import React from 'react';
import './Footer.css';
import { Button } from '../Button/Button';
import { Link } from 'react-router-dom';
import DocLogo from '../../media/imagenes/logo-black.png'

function Footer() {

  function SendMail(){
    let name = document.getElementById("txt-name").value;
    let mail = document.getElementById("txt-mail").value;
    let msj = document.getElementById("txt-mensaje").value;
    let chbx = document.getElementById("checkbox").checked

    if(chbx){
      window.location.href = "mailto:dr.joel.maldonado@gmail.com?subject="+name+"&body="+msj;
    }else{
      window.alert("Es necesario leer y aceptar la Política de Privacidad")
    }

    
  }

  return (
    <div className='footer-container'>
      <div className="footer-info">
        <h4>Contacto</h4>
        <p>Piso 10, Consultorio 1023, Rio Churubusco No. 601,</p>
        <p>Col. Xoco, C.P. 03339, CDMX.</p>
        <p>Lunes a viernes: 09:00 A.M.- 18:00 P.M</p>
        <p>Sábado: 10:00 A.M. - 15:00 P.M.</p>
        <p>Tel. Directo: (555)-204-3304</p>
        <p>Cel. (555)-144-5598</p>
        <p className='mail'>dr.joel.maldonado@gmail.com</p>
      </div>
      <section className='footer-mail'>
        <p className='footer-subscription-heading'>
         Escríbenos
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='nombre'
              type='text'
              placeholder='Nombre'
              id='txt-name'
            />
             <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Correo Electronico'
              id='txt-mail'
            />
            <br/>
            <textarea 
            className='footer-input'
            name='mensaje'
            placeholder='Mensaje'
            style={{resize: "none"}}
            rows={4}
            cols={65}
            id='txt-mensaje'>   
            </textarea>
            <br/>
            <input
            type="checkbox" id='checkbox' value="politica-privacidad"/>
            <label>Al enviar acepto la Política de Privacidad</label>
            <br/>
            <br/>
            <Button buttonStyle='btn--primary' className="btn-footer" onClick={SendMail}>
              Enviar</Button>
          </form>
        </div>
      </section>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src={DocLogo} width={200} height={100} style={{objectFit: "contain"}} alt='Dr. Joel Maldonado logo'/>
            </Link>
          </div>
          <small class='website-rights'>Aviso Legal | Política de Cookies (UE) | Política de Privacidad</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <a href='https://www.instagram.com/medicina_moderna_mexico/' target="_blank" className='social-icon-link instagram' rel='noreferrer'>
              <i class='fab fa-instagram' />
            </a>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;