export const MenuItems = [
    {
      title: 'Tratamientos',
      path: '/tratamientos',
      cName: 'dropdown-link'
    },
    {
      title: 'Paquetes',
      path: '/paquetes',
      cName: 'dropdown-link'
    },
    {
      title: 'Diagnóstico',
      path: '/diagnosticos',
      cName: 'dropdown-link'
    }
  ];