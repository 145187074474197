import React from 'react'
import './Tratamiento.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Tratamiento3 = () => {

    return (
        <div className='tratamiento'>
            <div className='tratamiento-container'>
                <div className='tratamiento-title'>
                    <h2>Tratamiento de varices sin cirugía.</h2>
                </div>
                <div className='tratamiento-section'>
                    <div className='tratamiento-section-info'>
                        <small>El procedimiento de eliminar varices, se realiza en el centro de diagnóstico y tratamiento, y mediante una primera consulta, que incluye la realización de la ecografía Doppler, permite el diagnóstico preciso, y se le propone la mejor opción terapéutica para su patología.Los síntomas más comunes de las personas que padecen varices son pesadez de piernas, hinchazón, calambres, dolor, cansancio, manchas e incluso úlceras en las fases más avanzadas.</small><br /><br />
                        <small>La insuficiencia venosa en forma de varices es una enfermedad frecuente en nuestro entorno, y que afecta casi a la mitad de la población.</small><br /><br />
                        <small>Las varices no son solamente un problema estético. Son una señal de que la circulación venosa es deficiente o está deteriorada y en ocasiones se convierte en un problema de salud que repercute directamente en la calidad de vida de aquellas personas que lo padecen, bien por el dolor y las molestias o en casos más graves, por las complicaciones en forma de úlceras.</small><br /><br />
                        <small>Las varices  son una afectación con un importante componente hereditario, pero además existen ciertos factores que favorecen su aparición como la obesidad, el sedentarismo,  los embarazos o la edad.</small><br /><br /><br />
                        <strong>¿Por qué tratar las varices?</strong><br /><br />
                        <small>Las varices no mejoran por sí solas, por lo que necesitan ser diagnosticadas y tratadas lo antes posible.</small><br /><br /><br />
                        <strong>Diagnóstico.</strong><br /><br />
                        <small>Realizamos una anamnesis y una exploración física completa, así como un estudio con Ecografía Doppler, que permite realizar un mapa de toda la circulación venosa de la pierna con el que determinaremos el tratamiento indicado según las necesidades del paciente.</small><br /><br /><br />
                        <strong>Tratamiento.</strong><br /><br />
                        <small>Con control de ecografía se introduce un fino catéter en la vena safena mayor o menor. Este catéter está acoplado a un motor que lo hace rotar y que genera un colapso en la vena. A continuación, se inyecta un líquido esclerosante. La combinación del efecto mecánico del catéter con el efecto químico del líquido esclerosante permite eliminar en una sola sesión un eje venoso superficial enfermo.</small><br /><br />
                        <small>No requiere anestesia, no necesita quirófano ni baja laboral, y se puede realizar vida normal al finalizar el procedimiento.</small><br /><br /><br />
                        <strong>Beneficios.</strong><br /><br />
                        <ul className="list-info">
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Seguro y efectivo.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>No requiere quirófano.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>No necesita anestesia.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Sin molestias antes ni después del tratamiento.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Rápida recuperación y retorno a las actividades cotidianas.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Excelente resultado estético.</li><br /><br />
                        </ul>
                        <strong>Preguntas frecuentes.</strong><br /><br />
                        <strong>¿Tras un tratamiento de varices hace falta baja laboral?</strong><br /><br />
                        <small>Este tratamiento está indicado en pacientes con nódulos tiroideos benignos, siempre confirmado con dos punciones previas con aguja fina (PAAF). Además, la presencia de dicho nódulo o nódulos debe estar asociado a la existencia de síntomas comprensivos como disfagia y afonía, problemas cosméticos o crecimiento del tumor en el tiempo. Se suele ablacionar nódulos no funcionantes mayores de 2 centímetros y nódulos hiperfuncionantes detectables en la ecografía, independientemente de su tamaño.</small><br /><br />
                        <strong>¿Los tratamientos de varices necesitan anestesia? ¿Son dolorosos?</strong><br /><br />
                        <small>El tratamiento es completamente indoloro. No se utiliza anestesia general, pero sí es necesaria una mínima cantidad de anestesia local para reducir la molestia inicial de la punción en la introducción del catéter.</small><br /><br />
                        <strong>¿Es suficiente con una sola sesión?</strong><br /><br />
                        <small >En caso de persistir alguna pequeña variz residual se tratan con pequeñas inyecciones de microespuma.</small><br /><br />
                        <strong>¿Cuánto tiempo voy a tardar en recuperarme tras la intervención?</strong><br /><br />
                        <small>Podrá volver a retomar sus actividades cotidianas sin problema de forma  inmediata después del tratamiento (incluso podrá practicar deporte). Deberá pasear a menudo y llevar una media de compresión durante 7-10 días tras el tratamiento.</small><br /><br /><br />
                        <div className="tratamiento-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tratamiento3