import React from 'react';
import Diagnostico2 from '../Diagnostic/Diagnostico2';

const ArteriografiaDiagnosticaNoSelectiva = () => {
  return <div>
      <Diagnostico2/>
  </div>;
};

export default ArteriografiaDiagnosticaNoSelectiva;
