import React from 'react'
import '../../App.css'
import { Button } from '../Button/Button'
import './Main.css'
import Video from '../../media/video/cover-video.mp4'
import {Link} from 'react-router-dom'

const Main = () => {
    return (
        <div className="main-container">
            <video src={Video} autoPlay muted loop />
            <h1>Dr. Joel Maldonado Velázquez.</h1>
            <p>Neuroradiología intervencionista | Terapia endovascular |  Radiología intervencionista</p>
            <div className="main-btns">
                <Button className="btns" buttonStyle="btn--primary" buttonSize="btn--large">
                    <Link to="/quienes_somos" style={{textDecoration: "none", color: "#000000"}}>Conóceme</Link>
                </Button>    
                <Button className="btns" buttonStyle="btn--primary" buttonSize="btn--large">
                    <Link to="/servicios" style={{textDecoration: "none", color: "#000000"}}>Servicios</Link>
                </Button>
            </div>
        </div>
    )
}

export default Main
