import React from 'react'
import './Diagnostico.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Diagnostico2 = () => {

    return (
        <div className='diagnostico'>
            <div className='diagnostico-container'>
                <div className='diagnostico-title'>
                    <h2>Arteriografía diagnóstica no selectiva.</h2>
                </div>
                <div className='diagnostico-section'>
                    <div className='diagnostico-section-info'>
                        <strong>Arteriografía de miembros inferiores.</strong><br/>
                        <small className="small">Es un examen invasivo que consiste en la punción de una arteria o de una vena, a través de una inyección de medio de contraste y, posteriormente, con la ayuda de rayos X se evalúa el sistema arterial o venoso, lo que permite el diagnóstico de enfermedades vasculares.</small><br/><br />
                        <strong> Arteriografía de miembros superiores.</strong><br/>
                        <small className="small">Es un examen invasivo que consiste en la punción de una arteria o de una vena, a través de una inyección de medio de contraste y, posteriormente, con la ayuda de rayos X se evalúa el sistema arterial o venoso, lo que permite el diagnóstico de enfermedades vasculares.</small><br/><br />
                        <strong>Arteriografía abdominal.</strong><br />
                        <small className="small">Es un procedimiento en el que se usa un tinte especial y rayos X para ver cómo fluye la sangre a través de la aorta. La aorta es la arteria mayor. Esta lleva la sangre del corazón a través del abdomen o vientre.</small><br /><br />
                        <strong>Arteriografía torácica.</strong><br />
                        <small className="small">Es un procedimiento en el que se usa un tinte especial y rayos X para ver cómo fluye la sangre a través del tórax.</small><br /><br />
                        <strong> Arteriografía pulmonar.</strong><br />
                        <small className="small">Es un examen para ver cómo fluye la sangre a través del pulmón.</small><br /><br />
                        <small>Todos nuestros estudios y procedimientos se llevan a cabo en un área especializada de una clínica u hospital certificado (dependiendo de la condición o tratamiento).</small><br /><br />
                        <div className="diagnostico-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diagnostico2