import React from 'react';
import Tratamiento1 from '../Tratamientos/Tratamiento1';

const Miomas = () => {
  return <div>
      <Tratamiento1/>
  </div>;
};

export default Miomas;
