import React from 'react';
import Diagnostico6 from '../Diagnostic/Diagnostico6';

const MuestreoVascular = () => {
  return <div>
      <Diagnostico6/>
  </div>;
};

export default MuestreoVascular;
