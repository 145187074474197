import React from 'react'
import Diagnostic from '../Diagnostic/Diagnostic'

const Diagnosticos = () => {
    return (
        <div>
            <Diagnostic/>
        </div>
    )
}

export default Diagnosticos
