import React from 'react';
import Diagnostico1 from '../Diagnostic/Diagnostico1';

const ArteriografiaDiagnosticaSelectiva = () => {
  return (
      <div>
          <Diagnostico1/>
      </div>  
    )
};

export default ArteriografiaDiagnosticaSelectiva;
