import React from 'react'
import Tratamiento from '../Tratamientos/Tratamiento'

const Tratamientos = () => {
    return (
        <div>
            <Tratamiento/>
        </div>
    )
}

export default Tratamientos
