import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/pages/Home'
import Footer from './components/Footer/Footer';
import Contacto from './components/pages/Contacto';
import Diagnosticos from './components/pages/Diagnosticos';
import Paquetes from './components/pages/Paquetes';
import QuienesSomos from './components/pages/QuienesSomos';
import Servicios from './components/pages/Servicios';
import Tratamientos from './components/pages/Tratamientos';
import ArteriografiaDiagnosticaSelectiva from './components/pages/ArteriografiaDiagnosticaSelectiva';
import ArteriografiaDiagnosticaNoSelectiva from './components/pages/ArteriografiaDiagnosticaNoSelectiva';
import FlebografiaDiagnosticaNoSelectiva from './components/pages/FlebografiaDiagnosticaNoSelectiva';
import FlebografiaDiagnosticaSelectiva from './components/pages/FlebografiaDiagnosticaSelectiva';
import EstudiosVPD from './components/pages/EstudiosVPD';
import MuestreoVascular from './components/pages/MuestreoVascular';
import DiagnosticoINVH from './components/pages/DiagnosticoINVH';
import DiagnosticoINVU from './components/pages/DiagnosticoINVU';
import PuncionVisceraSuperficial from './components/pages/PuncionVisceraSuperficial';
import PuncionVisceraProfunda from './components/pages/PuncionVisceraProfunda';
import Miomas from './components/pages/Miomas';
import HBP from './components/pages/HBP';
import TratamientoVarices from './components/pages/TratamientoVarices';

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/contacto' element={<Contacto/>} />
          <Route path='/diagnosticos' element={<Diagnosticos/>} />
          <Route path='/paquetes' element={<Paquetes/>} />
          <Route path='/quienes_somos' element={<QuienesSomos/>} />
          <Route path='/servicios' element={<Servicios/>} />
          <Route path='/tratamientos' element={<Tratamientos/>} />
          <Route path='/diagnosticos/arteriografia-diagnostica-selectiva' element={<ArteriografiaDiagnosticaSelectiva/>} />
          <Route path='/diagnosticos/arteriografia-diagnostica-no-selectiva' element={<ArteriografiaDiagnosticaNoSelectiva/>} />
          <Route path='/diagnosticos/flebografia-diagnostica-no-selectiva' element={<FlebografiaDiagnosticaNoSelectiva/>} />
          <Route path='/diagnosticos/flebografia-diagnostica-selectiva' element={<FlebografiaDiagnosticaSelectiva/>} />
          <Route path='/diagnosticos/estudios-vasculares-percutaneos-diagnosticos' element={<EstudiosVPD/>} />
          <Route path='/diagnosticos/muestreo-vascular' element={<MuestreoVascular/>} />
          <Route path='/diagnosticos/diagnostico-intervencionista-no-vascular-hepatico' element={<DiagnosticoINVH/>} />
          <Route path='/diagnosticos/diagnostico-intervencionista-no-vascular-urologico' element={<DiagnosticoINVU/>} />
          <Route path='/diagnosticos/puncion-de-viscera-superficial' element={<PuncionVisceraSuperficial/>} />
          <Route path='/diagnosticos/puncion-de-viscera-profunda' element={<PuncionVisceraProfunda/>} />
          <Route path='/tratamientos/miomas' element={<Miomas/>} />
          <Route path='/tratamientos/arterias-prostaticas' element={<HBP/>} />
          <Route path='/tratamientos/tratamiento-varices-sin-cirugia' element={<TratamientoVarices/>} />
        </Routes>
        <Footer/>
      </Router>

    </>
  );
}

export default App;
