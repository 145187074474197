import React from 'react';
import Diagnostico5 from '../Diagnostic/Diagnostico5';

const EstudiosVPD = () => {
  return <div>
      <Diagnostico5/>
  </div>;
};

export default EstudiosVPD;
