import React from 'react'
import Contact from '../Contact/Contact'

const Contacto = () => {
    return (
        <div>
            <Contact/>
        </div>
    )
}

export default Contacto
