import React from 'react'
import './Diagnostico.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Diagnostico5 = () => {

    return (
        <div className='diagnostico'>
            <div className='diagnostico-container'>
                <div className='diagnostico-title'>
                    <h2>Estudios vasculares percutáneos diagnósticos.</h2>
                </div>
                <div className='diagnostico-section'>
                    <div className='diagnostico-section-info'>
                        <strong>Esplenoportografía percutánea.</strong><br/>
                        <small className="small">Técnica radiográfica que consiste en la introducción de contraste en las estructuras vasculares del bazo, para realizar el estudio de los vasos del sistema portal, y poder obtener imágenes con fines diagnósticos.</small><br/><br />
                        <strong> Portografía directa.</strong><br/>
                        <small className="small">Está indicada cuando se prevé que después de una resección hepática el parénquima hepático remanente va a ser demasiado pequeño para mantener la función hepática esencial.</small><br/><br />
                        <strong>Estudio de malformaciones vasculares.</strong><br /><br />
                        <strong> Flebografía y manometría de shunt portocava percutánea (TIPS).</strong><br/>
                        <small className="small">Pasaje que permite el paso anómalo de fluidos de un lado del cuerpo humano a otro.</small><br/><br /><br />
                        <small>Todos nuestros estudios y procedimientos se llevan a cabo en un área especializada de una clínica u hospital certificado (dependiendo de la condición o tratamiento).</small><br /><br />
                        <div className="diagnostico-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diagnostico5