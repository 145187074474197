import React from 'react'
import Cards from '../Cards/Cards'

const Service = () => {
    return (
        <div>
            <Cards/>
        </div>
    )
}

export default Service
