import React from 'react'
import './Diagnostico.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Diagnostico1 = () => {

    return (
        <div className='diagnostico'>
            <div className='diagnostico-container'>
                <div className='diagnostico-title'>
                    <h2>Arteriografía diagnóstica selectiva.</h2>
                </div>
                <div className='diagnostico-section'>
                    <div className='diagnostico-section-info'>
                        <strong>Arteriografía arco aórtico selectiva.</strong><br/>
                        <small className="small">Es un procedimiento en el que se usa un tinte especial y rayos X para ver cómo fluye la sangre a través de la aorta</small><br/><br />
                        <strong>Arteriografía cerebral.</strong><br/>
                        <small className="small">Utiliza un catéter, la guía por rayos X y una inyección de material de contraste para examinar vasos sanguíneos en el cerebro para identificar anormalidades tales como aneurismas y enfermedades como la aterosclerosis (placa).</small><br/><br />
                        <strong>Arteriografía para planificación radiocirugía.</strong><br />
                        <small className="small">Este tratamiento utiliza radiación enfocada con precisión para destruir la malformación.</small><br /><br />
                        <strong>Test oclusión vascular.</strong><br />
                        <small className="small">La oclusión vascular es el bloqueo de las arterias o venas que llevan sangre fuera de la retina, esto impide a la retina filtrar la luz correctamente llegando a provocar en algunos casos la pérdida repentina de la vista.</small><br /><br />
                        <strong>Arteriografía médulo-espinal.</strong><br />
                        <small className="small">Se necesita para determinar la ubicación y las características de los vasos sanguíneos que forman parte de la malformación arteriovenosa.</small><br /><br />
                        <strong>Arteriografía renal.</strong><br />
                        <small className="small">El examen utiliza un tinte o colorante especial para ayudar a que las arterias aparezcan en la radiografía. Los vasos sanguíneos de los riñones no se ven con las radiografías ordinarias. El tinte circula por el catéter hacia la arteria renal.</small><br /><br />
                        <strong>Arteriografía suprarrenal.</strong><br />
                        <small className="small">Es un procedimiento por el cual se visualizan las arterias y las venas de las glándulas suprarrenales gracias a la administración de un contraste.</small><br /><br />
                        <strong>Arteriografía pélvica.</strong><br />
                        <small className="small">Es un examen para ver cómo fluye la sangre a través del área pélvica.</small><br /><br />
                        <strong>Arteriografía bronquial.</strong><br />
                        <small className="small">Es una exploración que sirve para ver las arterias de los bronquios. Actualmente es uno de los estudios más exactos que se puede hacer para conocer la causa y el lugar del sangrado pulmonar (hemoptisis).</small><br /><br />
                        <strong>Arteriografía pulmonar selectiva.</strong><br />
                        <small className="small">Es un examen para ver cómo fluye la sangre a través del pulmón.</small><br /><br />
                        <strong>Arteriografía visceral selectiva.</strong><br />
                        <small className="small">Es una valiosa adición al arsenal diagnóstico para la hemorragia gastrointestinal aguda y crónica.</small><br /><br />
                        <strong>Arteriografía de la mamaria interna.</strong><br />
                        <small className="small">Es una exploración que sirve para ver las arterias de las mamas.</small><br /><br />
                        <small>Todos nuestros estudios y procedimientos se llevan a cabo en un área especializada de una clínica u hospital certificado (dependiendo de la condición o tratamiento).</small><br /><br />
                    </div>
                    <div className="diagnostico-btn">
                      <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                        <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                      </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diagnostico1

