import React from 'react'
import Service from '../Servicios/Service'

const Servicios = () => {
    return (
        <div>
            <Service/>
        </div>
    )
}

export default Servicios
