import React from 'react'
import CardsPaquetes from '../Cards/CardsPaquetes'

const Paquete = () => {
    return (
        <div className='paquete'>
            <CardsPaquetes/>
        </div>
    )
}

export default Paquete
