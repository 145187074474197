import React from 'react'
import CardsDiagnosticos from '../Cards/CardsDiagnosticos'

const Diagnostic = () => {
    return (
        <div className='diagnostic'>
            <CardsDiagnosticos/>
        </div>
    )
}

export default Diagnostic
