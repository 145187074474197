import React from 'react'
import About from '../About/About'

const QuienesSomos = () => {
    return (
        <div>
            <About/>
        </div>
    )
}

export default QuienesSomos
