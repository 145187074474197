import React from 'react'
import './Contact.css'

const Contact = () => {
    return (
        <div className='contact'>
            <div className='contact-container'>
                <div className='contact-ubicacion'>
                    <h2>Ubicación.</h2>
                    <p>Piso 10, Consultorio 1023</p>
                    <p>Rio Churubusco No. 601,</p>
                    <p>Col. Xoco, C.P. 03339, CDMX.</p>
                    <div className='contact-ubicacion-map'>
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.2341856173634!2d-99.1697071856885!3d19.359009948047447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ffc0214dc77b%3A0xf9bf0cf41258931!2sAv.%20R%C3%ADo%20Churubusco%20601%2C%20Xoco%2C%20Benito%20Ju%C3%A1rez%2C%2003330%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1639158930194!5m2!1ses-419!2smx" 
                        style={{border: "2px solid #000"}}
                        title='ubicacion' 
                        allowfullscreen={false} 
                        loading="lazy">
                        </iframe>
                    </div>
                </div>
                <div className='contact-info'>
                    <h2>Contacto</h2>
                    <p><i class="fas fa-phone-square-alt"></i>{'  '}Tel. Directo: (555)-204-3304</p>
                    <p><i class="fas fa-phone-square-alt"></i>{'  '}Cel. (555)-144-5598</p>
                    <p><i class="fas fa-envelope-square"></i><a href="mailto:dr.joel.maldonado@gmail.com"
                    style={{textDecoration: "none", color: "#000", fontWeight: "bold"}}>
                        {'  '}dr.joel.maldonado@gmail.com
                    </a></p>
                    <p><i class="fab fa-whatsapp-square"></i><a 
                    href="https://api.whatsapp.com/send?phone=+525551445598"
                    style={{textDecoration: "none", color: "#000", fontWeight: "bold"}}>
                        {'  '}Envíame un mensaje de WhatsApp</a></p>
                </div>
            </div>
        </div>
    )
}

export default Contact
