import React from 'react';
import Diagnostico8 from '../Diagnostic/Diagnostico8';

const DiagnosticoINVU = () => {
  return <div>
      <Diagnostico8/>
  </div>;
};

export default DiagnosticoINVU;
