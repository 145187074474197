import React from 'react';
import Diagnostico9 from '../Diagnostic/Diagnostico9';

const PuncionVisceraSuperficial = () => {
  return <div>
      <Diagnostico9/>
  </div>;
};

export default PuncionVisceraSuperficial;
