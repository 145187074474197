import React from 'react'
import './Tratamiento.css'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'


const Tratamiento1 = () => {

    return (
        <div className='tratamiento'>
            <div className='tratamiento-container'>
                <div className='tratamiento-title'>
                    <h2>¿Qué son los miomas?</h2>
                </div>
                <div className='tratamiento-section'>
                    <div className='tratamiento-section-info'>
                        <small>Lo primero es saber y definir qué son los miomas uterinos.</small><br /><br />
                        <small>Los miomas uterinos son tumores benignos (no cancerígenos) que crecen en la pared del útero. Son los tumores más frecuentes del aparato genital femenino, entre el 40 y 50 % de mujeres a partir de los 40 años los padecen.</small><br /><br />
                        <small>Las causas de la aparición de los miomas uterinos no se conocen exactamente, aunque algunos expertos los han relacionado con una predisposición genética que condiciona una mayor sensibilidad a hormonas.</small><br /><br />
                        <small>El tamaño de los miomas uterinos es muy variable, desde el tamaño de una lenteja a la de una pelota de tenis. Algunos pueden llegar a ser del tamaño de un melón.</small><br /><br />
                        <small>En ocasiones pueden ser incluso mayores, llegando a aumentar el tamaño del útero hasta un punto similar al de un embarazo de 5 meses.</small><br /><br /><br />
                        <strong>Sintomas.</strong><br /><br />
                        <small>Dependiendo del tamaño, la localización  y el número de miomas uterinos, pueden aparecer diferentes síntomas:</small><br /><br />
                        <ul className='list-info'>
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Menstruaciones fuertes y prolongadas y/o sangrados adicionales, a veces liberando coágulos de sangre y con frecuencia causando anemia y alterando la vida normal de las mujeres.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}> Sensación de peso y presión a nivel pélvico.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Dolor y molestias en la pelvis.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Dolor en la espalda y las piernas.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}> Dolor durante el acto sexual.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Sensación constante de necesidad de orinar debido a la presión que el mioma ejerce sobre la vejiga de la orina.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Estreñimiento y gases debidos a la presión del mioma sobre el intestino grueso y el recto.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Aumento anormal del diámetro abdominal.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Los miomas son una de las causas de infertilidad, abortos espontáneos y partos prematuros.</li><br /><br />
                        </ul>
                        <strong>¿Cómo se diagnostican los miomas uterinos?</strong><br /><br />
                        <small>La mayoría de las veces se detectan durante una visita ginecológica rutinaria (palpación abdominal, tacto pélvico, etc.).</small><br /><br />
                        <small>En caso de sospecha, se diagnostican mediante una ecografía. Es el examen de primera elección para confirmar la presencia de fibromas.</small><br /><br />
                        <small>Si se quiere saber el número, la ubicación y el tamaño de los miomas uterinos, se suele realizar una Resonancia Magnética.</small><br /><br />
                        <small>Resonancia magnética antes de la embolización uterina y a los tres meses tras la misma. El estudio previo muestra el aumento de tamaño del útero que llega más allá de la altura del ombligo (flecha amarilla). La Resonancia a los tres meses de la embolización uterina muestra el mioma reducido de tamaño (flechas blancas) y sin riego sanguíneo, ya no comprime la vejiga. Las reglas con sangrados abundantes se han normalizado.</small><br /><br /><br />
                        <strong>Tratamiento.</strong><br /><br />
                        <small>Es el tratamiento de los miomas uterinos mediante embolización de las arterias uterinas.</small><br /><br />
                        <ul className="list-info">
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Es un procedimiento seguro y eficiente.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Todo se realiza bajo anestesia local y sedación.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Trata de una sola vez todos los miomas.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>La duración del tratamiento oscila entre 1 y 2 horas.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>La paciente permanece ingresada en el hospital entre 24 y 48 horas.</li><br /><br />
                        </ul>
                        <small>La embolización consiste en interrumpir el flujo sanguíneo de las arterias que alimentan los miomas. Con este procedimiento se detienen las hemorragias y se reduce el tamaño de los miomas. La parte sana del útero seguirá recibiendo sangre por otras arterias.</small><br /><br /><br />
                        <strong>¿Cómo se hace?</strong><br /><br />
                        <small>Llevó a cabo una evaluación de la paciente y luego un ultrasonido.</small><br /><br />
                        <small>La Resonancia magnética confirmará el tipo de miomas así como el número, tamaño y localización exacta antes de realizar la embolización.</small><br /><br />
                        <small>Se requiere también estudios de laboratorio en los que debe de incluir biometría hemática, tiempos de coagulación, creatinina y prueba de embarazo.</small><br /><br />
                        <small>Se realiza una embolización en una sala certificada de hemodinamia que dispone de un equipo de Rayos X especial.</small><br /><br />
                        <small>Una vez en sala de hemodinamia, a la paciente se le administran sedantes y analgésicos y se le coloca sonda vesical.</small><br /><br />
                        <small>Una vez con el catéter en su sitio, se introducen unas partículas de muy pequeño tamaño, que van cerrando las arterias que alimentan a los miomas.</small><br /><br />
                        <small>Terminado el procedimiento la paciente es llevada a su cuarto, donde permanecerá durante unas 18-48 horas recibiendo los analgésicos programados.</small><br /><br />
                        <small>La paciente se llevará a casa el tratamiento a seguir y dispondrá de un número de teléfono de contacto para consultar cualquier duda que le surja después de la intervención. A los seis meses se recomienda nueva Resonancia Magnética de la pelvis, para medir la reducción del volumen del útero y de los miomas.</small><br /><br /><br />
                        <strong>Anestesia y tratamiento del dolor</strong><br /><br />
                        <small>La embolización se realiza con anestesia local en el lugar donde se introduce el catéter. No se necesita anestesia general, pero la paciente estará sedada y así asegurar su máxima comodidad.</small><br /><br />
                        <small>La mayoría de las pacientes, en las horas siguientes al procedimiento, e incluso en los primeros días después de ser dadas de alta, sufren dolores cólicos en el bajo vientre, semejante a los dolores de la menstruación. Esto es debido al súbito corte en el abastecimiento sanguíneo de los miomas. Aunque esto puede resultar un poco incómodo, la paciente se lleva analgesia a casa.</small><br /><br /><br />
                        <strong>Resultados.</strong><br /><br />
                        <small>Ha quedado demostrado en diferentes estudios que la embolización de miomas uterinos es una buena alternativa a otro tipo de tratamientos más agresivos y con más complicaciones.</small><br /><br />
                        <small>El 95 % de las mujeres embolizadas cesan de sangrar y les desaparecen los otros síntomas acompañantes, como dolor, sensación de opresión.</small><br /><br />
                        <ul className='list-info'>
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>Las pacientes se recuperan pronto y pueden reanudar sus actividades diarias en un mínimo de tiempo.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>En una única sesión se pueden tratar todos los miomas a la vez.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}>La embolización se considera un tratamiento poco traumático ya que no se pierde el útero.</li><br />
                            <li style={{marginLeft: "35px", fontSize: "medium"}}> La embolización de miomas uterinos no impide que la paciente se someta después a otras intervenciones, en caso de que la embolización no resuelva los problemas (5 % de los casos).</li><br /><br /><br />
                        </ul>
                        <div className="tratamiento-btn">
                            <Button className="btn" buttonStyle="btn--primary" buttonSize="btn--large">
                                <Link to="/contacto" style={{textDecoration: "none", color: "#000000"}}>Agendar una cita</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tratamiento1