import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import DiagnosticoImg from '../../media/imagenes/diagnostico.jpg'

function CardsDiagnosticos() {
  return (
    <div className='cards'>
      <h1>Procedimientos diagnosticos.</h1>
      <div className='diagnostico-section-info'>
        <strong>Procedimientos de mínima invasión útiles para tratar patologías. Las imágenes se utilizan como herramienta para dirigir los procedimientos de intervención, que generalmente se realizan con agujas y catéteres.</strong>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de la arteriografía diagnóstica selectiva.'
              label='Arteriografía diagnóstica selectiva'
              path="/diagnosticos/arteriografia-diagnostica-selectiva"
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de la arteriografía diagnóstica no selectiva.'
              label='Arteriografía diagnóstica no selectiva'
              path="/diagnosticos/arteriografia-diagnostica-no-selectiva"
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de la flebografía diagnóstica no selectiva.'
              label='Flebografía diagnóstica no selectiva'
              path="/diagnosticos/flebografia-diagnostica-no-selectiva"
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de la flebografía diagnóstica selectiva.'
              label='Flebografía diagnóstica selectiva'
              path="/diagnosticos/flebografia-diagnostica-selectiva"
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de los estudios vasculares percutáneos diagnósticos.'
              label='Estudios vasculares percutáneos diagnósticos'
              path="/diagnosticos/estudios-vasculares-percutaneos-diagnosticos"
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca del muestreo vascular.'
              label='Muestreo vascular'
              path="/diagnosticos/muestreo-vascular"
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca del diagnóstico intervencionista no vascular hepático.'
              label='Diagnóstico intervencionista no vascular hepático'
              path="/diagnosticos/diagnostico-intervencionista-no-vascular-hepatico"
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca del diagnóstico intervencionista no vascular urológico.'
              label='Diagnóstico intervencionista no vascular urológico'
              path="/diagnosticos/diagnostico-intervencionista-no-vascular-urologico"
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de la punción de víscera superficial.'
              label='Punción de víscera superficial'
              path="/diagnosticos/puncion-de-viscera-superficial"
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={DiagnosticoImg}
              text='Conozca más acerca de la punción de víscera profunda.'
              label='Punción de víscera profunda'
              path="/diagnosticos/puncion-de-viscera-profunda"
            />
          </ul>  
        </div>
      </div>
    </div>
  );
}

export default CardsDiagnosticos;