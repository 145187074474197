import React from 'react';
import Diagnostico3 from '../Diagnostic/Diagnostico3';

const FlebografiaDiagnosticaNoSelectiva = () => {
  return <div>
      <Diagnostico3/>
  </div>;
};

export default FlebografiaDiagnosticaNoSelectiva;
