import '../../App.css'
import Main from '../Main/Main'
import React from 'react'

const Home = () => {
    return (
        <div>
            <Main/>   
        </div>
    )
}

export default Home

