import React from 'react'
import './About.css'
import DocImagen from '../../media/imagenes/docmaldonado.jpg'
import Form from '../Form/Form'

const About = () => {

    return (
        <div className='about'>
            <div className='about-container'>
                <div className='about-title'>
                    <h2>¿Quién es el Dr. Joel Maldonado?</h2>
                </div>
                <div className='about-section'>
                    <div className='about-section-info'>
                        <p>Médico cirujano egresado de la Universidad Autónoma del Estado de Hidalgo. Especialista en radiología diagnóstica y terapéutica, con subespecialidades en:</p>
                        <ul>
                            <li>Neuroradiología intervencionista del Instituto ENERI,beca SILAN. Argentina.</li>
                            <li>Radiología intervencionista: Cirugía endovascular, Cirugía percutánea mínima invasiva del Hospital General de México Dr.Eduardo Liceaga, México.</li>
                            <li>Resonancia Magnética: Neuroradiología, musculoesquelético, Body RM. Madrid, España.</li>
                        </ul>
                        <p>Jefe del servicio de Imagen en el Hospital San Angel Inn Universidad.<br/>Jefe del servicio de Imagen en el Hospital San Angel Inn Sur.</p>
                        <p>Certificaciones:</p>
                        <ul>
                            <li>Certificado por el Consejo Mexicano de Radiología e Imagen.</li>
                            <li>Certificado por el Consejo Mexicano de Radiología e Imagen en Radiología Intervencionista.</li>
                        </ul>
                    </div>
                    <div className="about-section-search">
                        <div className='about-img'>
                            <img src={DocImagen} style={{objectFit: "cover"}} alt='doc maldonado'></img>
                        </div>
                        <div className='about-search'>
                            <Form/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
