import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PaquetesImg from '../../media/imagenes/paquete.jpg'

function CardsPaquetes() {
  return (
    <div className='cards'>
      <h1>Paquetes.</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={PaquetesImg}
              text='Descripción...
              Precio ....'
              label='Paquete 1'
              path="#"
            />
            <CardItem
              src={PaquetesImg}
              text='Descripción...
              Precio ....'
              label='Paquete 2'
              path="#"
            />
            <CardItem
              src={PaquetesImg}
              text='Descripción...
              Precio ....'
              label='Paquete 3'
              path="#"
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={PaquetesImg}
              text='Descripción...'
              label='Paquete 3'
              path="#"
            />
            <CardItem
              src={PaquetesImg}
              text='Descripción...
              Precio ....'
              label='Paquete 4'
              path="#"
            />
            <CardItem
              src={PaquetesImg}
              text='Descripción...
              Precio ....'
              label='Paquete 5'
              path="#"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsPaquetes;