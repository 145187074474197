import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import TratamientosImg from '../../media/imagenes/tratamiento.jpg'
import DiagnosticoImg from '../../media/imagenes/diagnostico.jpg'
import PaquetesImg from '../../media/imagenes/paquete.jpg'

function Cards() {
  return (
    <div className='cards'>
      <h1>Conoce nuestros servicios.</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={TratamientosImg}
              text='Conoce los diferentes tratamientos que ofrecemos.'
              label='Tratamientos'
              path='/tratamientos'
            />
            <CardItem
              src={DiagnosticoImg}
              text='Conoce nuestros diferentes procedimientos diagnósticos.'
              label='Procedimientos diagnosticos'
              path='/diagnosticos'
            />
            <CardItem
              src={PaquetesImg}
              text='Conoce los diferentes paquetes con los que contamos.'
              label='Paquetes'
              path='/paquetes'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;