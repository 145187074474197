import React from 'react';
import Tratamiento3 from '../Tratamientos/Tratamiento3';

const TratamientoVarices = () => {
  return <div>
      <Tratamiento3/>
  </div>;
};

export default TratamientoVarices;
