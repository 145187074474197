import React from 'react'
import Paquete from '../Paquetes/Paquete'

const Paquetes = () => {
    return (
        <div>
            <Paquete/>
        </div>
    )
}

export default Paquetes
