import React from 'react';
import Diagnostico7 from '../Diagnostic/Diagnostico7';

const DiagnosticoINVH = () => {
  return <div>
      <Diagnostico7/>
  </div>;
};

export default DiagnosticoINVH;
