import React from 'react';
import Diagnostico10 from '../Diagnostic/Diagnostico10';

const PuncionVisceraProfunda = () => {
  return <div>
      <Diagnostico10/>
  </div>;
};

export default PuncionVisceraProfunda;
