import React from 'react'
import { Button } from '../Button/Button'
import './Form.css'
import { Link } from 'react-router-dom'
import '../Button/Button.css'

const Form = () => {

    function SendMailForm(){
        let nombre = document.getElementById("nombre").value;
        let email = document.getElementById("email").value;
        let telefono = document.getElementById("telefono").value;
        let interes = document.getElementById("interes").value;
        let desc = document.getElementById("desc").value;
        let fecha = document.getElementById("date").value;

        window.location.href = "mailto:dr.joel.maldonado@gmail.com?subject="+interes+"&body=Nombre:%20"+nombre+
        "%20E-mail:%20"+email+"%20Teléfono:%20"+telefono+"%20Problema:%20"+desc+"%20FechaCita:%20"+fecha;
    }

    return (
        <div className='form-container'>
            <form className='form'>
                <h1>
                    ¿Buscas un radiologo intervencionista?
                </h1>
                <div className="form-inputs">
                    <label htmlFor='nombre' className='form-label'>
                        Nombre
                    </label>
                    <input type="text"
                        id="nombre"
                        name='nombre'
                        className='form-input'
                        placeholder='Ingrese su nombre'/>
                </div>
                <div className="form-inputs">
                    <label htmlFor='email' className='form-label'>
                        Correo Electrónico
                    </label>
                    <input type="text"
                        id="email"
                        name='email'
                        className='form-input'
                        placeholder='Ingrese su correo electrónico'/>
                </div>
                <div className="form-inputs">
                    <label htmlFor='telefono' className='form-label'>
                        Teléfono
                    </label>
                    <input type="text"
                        id="telefono"
                        name='telefono'
                        className='form-input'
                        placeholder='Ingrese su número telefónico'/>
                </div>
                <div className="form-inputs">
                    <label htmlFor='interes' className='form-label'>
                        Área de interes
                    </label>
                    <input type="text"
                        id="interes"
                        name='interes'
                        className='form-input'
                        placeholder='Ingrese el área de su interes'/>
                </div>
                <div className="form-inputs">
                    <label htmlFor='desc' className='form-label'>
                        Descripción del problema
                    </label>
                    <input type="text"
                        id="desc"
                        name='desc'
                        className='form-input'
                        placeholder='Describa su problema'/>
                </div>
                <div className='form-inputs'>
                    <label htmlFor='date' className='form-label'>
                        Fecha para egendar cita
                    </label>
                    <input type="date"
                    id='date'
                    name='date'
                    className='form-input'
                    placeholder='Seleccione una fecha'
                    />
                </div>
                <div className='btn-politica'>
                    <Button className="btns" buttonStyle="btn--primary" buttonSize="btn--medium">
                        <Link to="#" style={{textDecoration: "none", color: "#000"}}>Politica de privacidad</Link>
                    </Button>
                </div>
                <div className='btn-buscar'>
                    <Button className="btns" buttonStyle="btn--primary" buttonSize="btn--large" onClick={SendMailForm}>
                        Buscar
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default Form
